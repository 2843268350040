/* eslint-disable no-console */
/* eslint-disable no-undef */

import React, { useState, useEffect } from "react";
import { get } from "axios";

function RssFeedToListOfJobs(xmlFeed) {
  // eslint-disable-next-line no-undef
  const rssDoc = new DOMParser().parseFromString(xmlFeed, "text/xml");
  const elements = rssDoc.getElementsByTagName("item");
  const get = (element, tag) =>
    element.getElementsByTagName(tag)[0].textContent;
  const ret = [];
  for (const element of elements) {
    ret.push({
      title: get(element, "title"),
      description: get(element, "description"),
      link: get(element, "link")
    });
  }
  return ret;
}

const HrFeed = ({ fields }) => {
  const url = fields.url.value;
  const [data, setData] = useState(null);
  const [error, setError] = useState(error);
  useEffect(() => {
    get(url, { headers: { Accept: "application/rss+xml" } })
      .then(r => setData(RssFeedToListOfJobs(r.data)))
      .catch(error => {
        setError("Error while loading job openings");
        console.log(error);
      });
  }, [url]);
  return (
    <div className="hr-feed">
      {error ? (
        <p>{error}</p>
      ) : !data ? (
        <p>Loading job openings</p>
      ) : data.length === 0 ? (
        <p> No job openings are currently available</p>
      ) : (
        data.map((d, i) => (
          <a href={d.link} key={i} style={{ color: "white" }}>
            <h3>{d.title}</h3>
            <article dangerouslySetInnerHTML={{ __html: d.description }} />
          </a>
        ))
      )}
    </div>
  );
};

export default HrFeed;
