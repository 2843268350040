import React from "react";
import { Link } from "react-router-dom";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";

const ArticleSummary = ({ fields }) => (
  <div>
    <h2>
      <Text field={fields.title} />
    </h2>
    <p>
      <Text field={fields.date} />
    </p>
    <RichText field={fields.summary} />
    <Link to={fields.link.value.href}>Read more...</Link>
  </div>
);

export default ArticleSummary;
