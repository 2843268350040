import React, { memo } from "react";
import { Link } from "react-router-dom";
import ArrowDown from "./ArrowDown";

// Renders next page link and support scrolling using scroll context

const NextPageLink = memo(({ url }) => {
  if (!url)
    return (
      <div className="empty-arrow">
        <ArrowDown />
      </div>
    );
  return (
    <Link to={url} rel="next">
      <ArrowDown />
    </Link>
  );
});
export default NextPageLink;
