import React, { memo } from "react";
import { Link } from "react-router-dom";
import ArrowUp from "./ArrowUp";

// Renders next page link and support scrolling using scroll context

const PreviousPageLink = memo(({ url }) => {
  if (!url)
    return (
      <div className="empty-arrow">
        <ArrowUp />
      </div>
    );
  return (
    <Link to={url} rel="next">
      <ArrowUp />
    </Link>
  );
});
export default PreviousPageLink;
