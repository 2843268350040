import React from "react";
import { get } from "lodash";

const Video = props => {
  const src = get(props, "fields.video.value.src");
  return src ? (
    <div>
      <video controls playsInline style={{ width: "100%" }}>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  ) : (
    <></>
  );
};

export default Video;
