/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-empty */
import React from "react";
import i18n from "i18next";
import Helmet from "react-helmet";
import {
  isEditorActive,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { layoutServiceFactory } from "./lib/layout-service-factory";
import { layoutService } from "./layout-service";
import { dataFetcher } from "./dataFetcher";
import config from "./temp/config";
import Layout from "./Layout";
import NotFound from "./NotFound";
import Route from "./Route";
import { cloneDeep, get } from "lodash";

/* eslint-disable no-console */

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.
let ssrInitialState = null;

const duration = 550;

class RouteHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: true,
      defaultLanguage: config.defaultLanguage,
      routeData: ssrInitialState,
      loading: false
    };

    const routeData = this.props.sitecoreContext;

    if (
      ssrInitialState &&
      ssrInitialState.sitecore &&
      ssrInitialState.sitecore.route
    ) {
      // set the initial sitecore context data if we got SSR initial state
      this.props.updateSitecoreContext({
        route: ssrInitialState.sitecore.route,
        itemId: ssrInitialState.sitecore.route.itemId,
        ...ssrInitialState.sitecore.context
      });
    }

    // route data from react-router - if route was resolved, it's not a 404
    if (props.route !== null) {
      this.state.notFound = false;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (routeData && !routeData.route) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (routeData && routeData.language) {
      this.state.defaultLanguage = routeData.language;
    }

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  componentDidMount() {
    // if no existing routeData is present (from SSR), get Layout Service fetching the route data or ssr render complete
    if (!this.props.sitecoreContext || this.props.ssrRenderComplete) {
      this.updateRouteData();
    }
  }

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  updateRouteData() {
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || "/";
    if (!sitecoreRoutePath.startsWith("/")) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }

    const language =
      this.props.route.match.params.lang || this.state.defaultLanguage;

    this.setState({ loading: true });
    try {
      if (window) window.scrollTo({ top: 0, behavior: "smooth" });
    } catch {} //MS EDGE
    // get the route data for the new route
    // getRouteData(sitecoreRoutePath, language)
    //   .then(
    //     routeData =>
    //       new Promise((resolve, reject) => {
    //         const bImage = get(
    //           routeData,
    //           "sitecore.route.fields.backgroundImage.value.src"
    //         );
    //         const img = new Image();

    //         if (!bImage) {
    //           resolve(routeData);
    //           return;
    //         }
    //         const tm = setTimeout(() => {
    //           img.onload = () => {};
    //           resolve(routeData);
    //         }, 20000);
    //         img.src = bImage;
    //         img.onload = () => {
    //           clearTimeout(tm);
    //           resolve(routeData);
    //         };
    //       })
    //   )
    //   .then(
    //     routeData => {
    //       this.setState({ loading: false });
    //       if (
    //         routeData !== null &&
    //         routeData.sitecore &&
    //         routeData.sitecore.route
    //       ) {
    //         // set the sitecore context data and push the new route
    //         this.props.updateSitecoreContext({
    //           route: routeData.sitecore.route,
    //           itemId: routeData.sitecore.route.itemId,
    //           ...routeData.sitecore.context
    //         });
    //         if (this.state.routeData == null) {
    //           this.setState({ routeData, notFound: false });
    //         } else {
    //           this.setState({ routeData, notFound: false });
    //         }
    //       } else {
    //         this.setState({ routeData, notFound: true });
    //       }
    //     },
    //     () => this.setState({ loading: false })
    //   );
    // instantiate the dictionary service.
    const layoutServiceInstance = layoutServiceFactory.create();

    // // get the route data for the new route
    layoutServiceInstance
      .fetchLayoutData(sitecoreRoutePath, language)
      .then(
        routeData =>
          new Promise((resolve, reject) => {
            const bImage = get(
              routeData,
              "sitecore.route.fields.backgroundImage.value.src"
            );
            const img = new Image();

            if (!bImage) {
              resolve(routeData);
              return;
            }
            const tm = setTimeout(() => {
              img.onload = () => {};
              resolve(routeData);
            }, 20000);
            img.src = bImage;
            img.onload = () => {
              clearTimeout(tm);
              resolve(routeData);
            };
          })
      )
      .then(routeData => {
        if (
          routeData !== null &&
          routeData.sitecore &&
          routeData.sitecore.route
        ) {
          // set the sitecore context data and push the new route
          this.props.updateSitecoreContext({
            route: routeData.sitecore.route,
            itemId: routeData.sitecore.route.itemId,
            ...routeData.sitecore.context
          });

          if (this.state.notFound) {
            this.setState({ notFound: false });
          }
        } else {
          this.setState({ notFound: true }, () => {
            const context =
              routeData && routeData.sitecore
                ? routeData.sitecore.context
                : null;

            this.props.updateSitecoreContext(context);
          });
        }
      });
  }
  updateOneTrust() {
    OneTrust.initializeCookiePolicyHtml();
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage =
      this.props.route.match.params.lang || this.state.defaultLanguage;

    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage);
    }
  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url;
    const newRoute = this.props.route.match.url;

    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return;
    }

    // if in Sitecore editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isEditorActive()) {
      window.location.assign(newRoute);
      return;
    }

    this.updateLanguage();
    this.updateRouteData();
    //this.updateOneTrust();
  }

  render() {
    const { notFound, loading } = this.state;
    const routeData = this.props.sitecoreContext;

    // no route data for the current route in Sitecore - show not found component.
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    // if (notFound && routeData) {
    //   return (
    //     <div>
    //       <Helmet>
    //         <title>{i18n.t("Page not found")}</title>
    //       </Helmet>
    //       <NotFound context={routeData} />
    //     </div>
    //   );
    // }

    if (notFound) {
      return (
        <div>
          <Helmet>
            <title>Page not found</title>
          </Helmet>
          <NotFound
            context={
              routeData && routeData.sitecore && routeData.sitecore.context
            }
          />
        </div>
      );
    }

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!routeData) {
      return null;
    }
    //return <Layout route={routeData.route} />;
    return <Route data={routeData} loading={loading} />;
  }
}

function getRouteData(route, language) {
  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: { sc_lang: language, sc_apikey: config.sitecoreApiKey },
    fetcher: dataFetcher
  };

  return layoutService.fetchLayoutData(route, language).catch(error => {
    if (
      error.response &&
      error.response.status === 404 &&
      error.response.data
    ) {
      return error.response.data;
    }

    // eslint-disable-next-line no-undef
    console.error("Route data fetch error", error, error.response);

    return null;
  });
}

export default withSitecoreContext({ updatable: true })(RouteHandler);
