import { createContext } from "react";
import memo from "memoize-one";
import { get } from "lodash";

export const getTransitionFromRoute = memo(route => {
  return {
    color: get(route, "fields.color.fields.color.value") || "#F8475E",
    initialPhaseTime: 300,
    mainPhaseTime: 600,
    finalPhaseTime: 300,
    getTotalTime() {
      return this.initialPhaseTime + this.mainPhaseTime + this.finalPhaseTime;
    }
  };
});

export default createContext(getTransitionFromRoute(null));
