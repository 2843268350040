import React, { useContext } from "react";
import TransitionContext from "../../SubComponents/TransitionContext";
import { Link as LinkSitecore } from "@sitecore-jss/sitecore-jss-react";
import { Link as LinkReact } from "react-router-dom";

const MenuLink = ({ fields }) => {
  let currentUrlPath = "";
  if (typeof window !== "undefined") {
    currentUrlPath = window.location.pathname;
  }
  let linkUrl = fields.link.value.href;
  const color = useContext(TransitionContext).color;
  const style = currentUrlPath.startsWith(linkUrl)
    ? { borderBottom: "solid " + color }
    : {};
  if (
    typeof window !== "undefined" &&
    linkUrl.startsWith(window.location.origin)
  )
    linkUrl = linkUrl.substr(window.location.origin.length);
  if (linkUrl.startsWith("/en/")) linkUrl = linkUrl.substr(3);

  return (
    <div className="top-menu-link" style={style}>
      {!linkUrl.startsWith("/") ? (
        <LinkSitecore field={fields.link} target="_blank" />
      ) : (
        <LinkReact to={linkUrl}>{fields.link.value.text}</LinkReact>
      )}
    </div>
  );
};

export default MenuLink;
