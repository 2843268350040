import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import NextPageLink from "../NextPageLink";
import PreviousPageLink from "../PreviousPageLink";
import logo from "../../assets/logo-hfsb.png";
import isClimate from "../../isClimateWebsite";

const emptyStyle = {};

const Menu = ({
  data,
  divClass,
  getSelectedStyle,
  nextUrl,
  previousUrl,
  color,
  id,
  selected,
  multipartArticle,
  ...props
}) => {
  const selectedStyle = useMemo(getSelectedStyle, [getSelectedStyle]);
  const borderStyle = useMemo(() => ({ borderColor: color }), [color]);
  let className = "outer-menu";
  if (divClass) {
    className += " " + divClass;
  }
  const isRight = divClass !== undefined;
  return (
    <div className={className} id={id}>
      <div className="spacer" />
      {!multipartArticle && data && (
        <div className="menu" style={borderStyle}>
          {data.length > 1 && isRight && <PreviousPageLink url={previousUrl} />}
          {data &&
            data.length > 1 &&
            data.map(
              x =>
                x.menuPosition > 0 && (
                  <Link
                    key={x.id}
                    to={x.url}
                    style={x == selected ? selectedStyle : emptyStyle}
                    {...props}
                  >
                    {!isRight ? x.pageTitle : x.menuTitle}
                  </Link>
                )
            )}
          {data.length > 1 && isRight && <NextPageLink url={nextUrl} />}
        </div>
      )}
      <div
        className="spacer"
        style={{ display: "flex", flexDirection: "column-reverse" }}
      >
        {!isRight && (
          <div className="copyright">
            {isClimate && (
              <>
                <a
                  href="https://nephila.com"
                  className="link-main-site"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Nephila main site &#62; <br />
                </a>
                <br />
              </>
            )}
            <img src={logo} alt="" style={{ height: "2rem" }} />
            <br />
            <br />
            <span>&#9400; Nephila Capital Ltd.</span>
            <br />
            <br />
            <Link to="/disclaimer">Disclaimer</Link>
            {" | "}
            <Link className="innerCopyrightLink" to="/disclaimer/terms-of-use">
              Terms of Use
            </Link>
            {" | "}
            <Link to="/disclaimer/privacy-policy">Privacy Policy</Link>
            <br />
            <Link to="/disclaimer/modern-slavery-act">Modern Slavery Act</Link>
            {" | "}
            <Link to="/disclaimer/cookie-statement">Cookie Statement</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
