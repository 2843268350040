import _ from "lodash";
const normalizeGuid = guid => {
  return guid && guid.length == 36
    ? guid.replace(/-/g, "").toUpperCase()
    : guid;
};

const makeNavigationData = (x, isRight) =>
  x && {
    url: x.url,
    menuTitle: x.menuTitle.value,
    pageTitle: x.pageTitle.value,
    id: x.id,
    color: x.color,
    isRight: isRight,
    menuPosition: x.menuPosition && x.menuPosition.value,
    isHidden: x.isHidden && x.isHidden.value && x.isHidden.value != "0",
    multipartArticle:
      x.multipartArticle &&
      x.multipartArticle.value &&
      x.multipartArticle.value != "0"
  };

export const Direction = {
  RIGHT: 0,
  DOWN: 1,
  LEFT: 2,
  UP: 3
};
const emptyPosition = [null, null];
export class NavigationData {
  constructor(gqlData) {
    this.data = [];
    this.itemMappingById = {};
    this.itemMappingByName = {};
    this.itemMappingByUrl = {};
    if (!gqlData || !gqlData.contextItem) return;
    var menuData = _.sortBy(gqlData.contextItem.children, [
      el => el.menuPosition && +el.menuPosition.value
    ]);
    for (const item of menuData) {
      const children = _.sortBy(item.children, [
        el => el.menuPosition && +el.menuPosition.value
      ]);
      const position = [this.data.length, null];
      this.itemMappingById[item.id] = position;
      this.itemMappingByName[item.name] = position;
      this.itemMappingByUrl[item.url] = position;

      children.forEach((innerItem, i) => {
        const position = [this.data.length, i];
        this.itemMappingById[innerItem.id] = position;
        this.itemMappingByName[innerItem.name] = position;
        this.itemMappingByUrl[innerItem.url] = position;
      });

      this.data.push({
        ...makeNavigationData(item, false),
        children: children.map(c => makeNavigationData(c, true))
      });
    }
  }
  getLeftMenuData() {
    return this.data.filter(d => !d.isHidden);
  }
  getRightMenuData(route) {
    const position = this.getSelectedPosition(route);
    const [i] = position;
    if (this.data[i])
      return [
        this.data[i],
        ...this.data[i].children.filter(c => c.menuPosition)
      ];
  }
  getSelectedPosition(route) {
    if (typeof window !== "undefined") {
      const windowPathName = window.location && window.location.pathname;

      if (windowPathName) {
        const path = windowPathName.split("/");
        if (path.length == 4) {
          let pos = this.itemMappingByUrl["/" + path[1] + "/" + path[2]];
          if (pos && this.data[pos[0]].children[pos[1]].menuPosition)
            return [...pos, 1];
          return [...this.itemMappingByUrl["/" + path[1]], 1] || emptyPosition;
        }
      }
    }
    if (route.databaseName === "available-in-connected-mode") {
      return this.itemMappingByName[route.name] || emptyPosition;
    } else {
      return this.itemMappingById[normalizeGuid(route.itemId)] || emptyPosition;
    }
  }
  getBreadCrumbs([i, j]) {
    if (i === null) return [];
    if (j === null) return [this.data[i].pageTitle, this.data[i].menuTitle];
    return [this.data[i].pageTitle, this.data[i].children[j].menuTitle];
  }
  getBurgerMenuData() {
    const ret = [];
    for (const item of this.data) {
      if (item.menuPosition) ret.push(item);
      for (const innerItem of item.children) {
        if (item.menuPosition) ret.push(innerItem);
      }
    }
    return ret;
  }
  getNext(direction, [i, j, k]) {
    if (k != undefined) return null;
    if (i != null) {
      const currentItem = j == null ? this.data[i] : this.data[i].children[j];
      if (!currentItem.menuPosition) return null;
    }
    const main = this.itemMappingByUrl["/main"];
    switch (direction) {
      case Direction.RIGHT: {
        return this.data[i - 1];
      }
      case Direction.DOWN: {
        if (i == null) return this.data[main];
        let newj = j != null ? j + 1 : 0;
        while (
          this.data[i].children[newj] &&
          !this.data[i].children[newj].menuPosition
        )
          newj++;

        return this.data[i].children[newj];
      }
      case Direction.LEFT: {
        return this.data[i + 1];
      }
      case Direction.UP: {
        if (j == null) return null;
        let upJ = j - 1;
        while (upJ > 0) {
          if (this.data[i].children[upJ].menuPosition)
            return this.data[i].children[upJ];
          upJ--;
        }
        return this.data[i];
      }
    }
  }
}
