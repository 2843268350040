import React, { useMemo, useContext, useState } from "react";
import TransitionContext from "../TransitionContext";
import { get } from "lodash";
import memo from "memoize-one";
import { Transition } from "react-transition-group";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

const getStylingFromRoute = (route, randomVideoNumber) => ({
  overlayColor:
    get(route, "fields.overlay.fields.backgroundColor.value") || null,
  isDark: get(route, "fields.isDark.value") || false,
  imageUrl: get(route, "fields.backgroundImage.value.src"),
  color: get(route, "fields.color.fields.color.value") || "#F8475E",
  video: get(
    route,
    "placeholders.jss-main.[" + randomVideoNumber + "].fields.video.value.src"
  )
});
const random = Math.random();

const NONE = 0;
const UP = 1;
const RIGHT = 2;
const DOWN = 3;
const LEFT = 4;
const getDirection = memo((routePrev, routeNext) => {
  if (routePrev == routeNext) return NONE;
  const positionNext = get(routeNext, "fields.menuPosition.value");
  const positionPrev = get(routePrev, "fields.menuPosition.value");
  if (positionNext == positionPrev) return NONE;
  const diff = positionNext - positionPrev;
  if (diff < 10 && diff > 0) return DOWN;
  if (diff > -10 && diff < 0) return UP;

  if (positionNext > positionPrev) return RIGHT;
  return LEFT;
});

const setRandomVideoNumber = route => {
  function isVideo(element) {
    return element.componentName == "IndexVideo";
  }
  const videos = get(route, "placeholders.jss-main").filter(isVideo);
  return Math.floor(random * Math.floor(videos.length));
};

const BackgroundLayer = ({ route }) => {
  const randomVideoNumber = setRandomVideoNumber(route);
  const { initialPhaseTime, mainPhaseTime } = useContext(TransitionContext);
  const [previousRoute, setPreviousRoute] = useState(route);
  const direction = getDirection(route, previousRoute);
  const replaceRoute = () => setPreviousRoute(route);
  const prevStyleObj = useMemo(
    () => getStylingFromRoute(previousRoute, randomVideoNumber),
    [previousRoute]
  );
  const nextStyleObj = useMemo(
    () => getStylingFromRoute(route, randomVideoNumber),
    [route]
  );
  const transitionTime = initialPhaseTime * 2 + mainPhaseTime + "ms";
  const prevDivStyle = useMemo(
    () => ({
      position: "fixed",
      backgroundImage: `url(${prevStyleObj.imageUrl})`,
      backgroundColor: prevStyleObj.isDark ? "#142a37" : "#ffffff",
      borderTopColor: prevStyleObj.color
    }),
    [prevStyleObj]
  );
  const prevTransitionStyle = useMemo(
    () => ({
      transition: "transform " + transitionTime + " ease-in-out",
      transform: `translate3d(${
        direction == LEFT ? "-100vw" : direction == RIGHT ? "100vw" : "0px"
      }, ${
        direction == UP ? "-100vh" : direction == DOWN ? "100vh" : "0px"
      }, 0)`
    }),
    [transitionTime, direction]
  );
  const nextTransitionStyle = useMemo(
    () => ({
      transition: "transform " + transitionTime + " ease-in-out",
      transform: `translate3d(${
        direction == LEFT ? "-100vw" : direction == RIGHT ? "100vw" : "0px"
      }, ${
        direction == UP ? "-100vh" : direction == DOWN ? "100vh" : "0px"
      }, 0)`
    }),
    [transitionTime, direction]
  );
  const prevDivStyleAnim = useMemo(
    () => ({
      ...prevDivStyle,
      ...(prevStyleObj.imageUrl != nextStyleObj.imageUrl
        ? prevTransitionStyle
        : {})
    }),
    [prevDivStyle, nextStyleObj, direction, prevTransitionStyle]
  );
  const nextDivStyleInitial = useMemo(
    () => ({
      position: "fixed",
      backgroundImage: `url(${nextStyleObj.imageUrl})`,
      backgroundColor: nextStyleObj.isDark ? "#142a37" : "#ffffff",
      left: direction == LEFT ? "100vw" : direction == RIGHT ? "-100vw" : "0vw",
      top: direction == UP ? "100vh" : direction == DOWN ? "-100vh" : "0vw"
    }),
    [prevStyleObj, direction]
  );
  const nextDivStyleEnd = useMemo(
    () => ({
      ...nextDivStyleInitial,
      ...(prevStyleObj.imageUrl != nextStyleObj.imageUrl
        ? nextTransitionStyle
        : {})
    }),
    [prevDivStyle, nextStyleObj, direction, prevTransitionStyle]
  );
  const prevOverlayStyle = useMemo(
    () => ({
      backgroundColor: prevStyleObj.overlayColor
    }),
    [prevStyleObj.overlayColor]
  );
  const prevOverlayStyleAnim = useMemo(
    () => ({
      ...prevOverlayStyle,
      ...(prevStyleObj.overlayColor != nextStyleObj.overlayColor
        ? prevTransitionStyle
        : {})
    }),
    [prevOverlayStyle, prevTransitionStyle, prevStyleObj, nextStyleObj]
  );
  const nextOverlayStyle = useMemo(
    () => ({
      backgroundColor: nextStyleObj.overlayColor,
      left: direction == LEFT ? "100vw" : direction == RIGHT ? "-100vw" : "0vw",
      top: direction == UP ? "100vh" : direction == DOWN ? "-100vh" : "0vw"
    }),
    [nextStyleObj.overlayColor, direction]
  );
  const nextOverlayStyleAnim = useMemo(
    () => ({
      ...nextOverlayStyle,
      ...(prevStyleObj.overlayColor != nextStyleObj.overlayColor
        ? nextTransitionStyle
        : {})
    }),
    [nextOverlayStyle, nextTransitionStyle, prevStyleObj, nextStyleObj]
  );
  return (
    <>
      {
        <Transition
          in={direction != NONE}
          onEntered={replaceRoute}
          timeout={{ enter: initialPhaseTime * 2 + mainPhaseTime, exit: 0 }}
        >
          {state => (
            <>
              <div
                style={state == "entering" ? prevDivStyleAnim : prevDivStyle}
                className="background-div"
              >
                {prevStyleObj.video && (
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="backgroundVideo"
                  >
                    <source
                      src={prevStyleObj.video}
                      type="video/mp4"
                      className="backgroundVideo"
                    />
                  </video>
                )}
                <div
                  className="overlay"
                  style={
                    state == "entering"
                      ? prevOverlayStyleAnim
                      : prevOverlayStyle
                  }
                />
              </div>
              <div
                style={
                  state == "entering" ? nextDivStyleEnd : nextDivStyleInitial
                }
                className="background-div"
              >
                {nextStyleObj.video && (
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="backgroundVideo"
                  >
                    <source
                      src={nextStyleObj.video}
                      type="video/mp4"
                      className="backgroundVideo"
                    />
                  </video>
                )}
                <div
                  className="overlay"
                  style={
                    state == "entering"
                      ? nextOverlayStyleAnim
                      : nextOverlayStyle
                  }
                />
              </div>
            </>
          )}
        </Transition>
      }
    </>
  );
};

export default withSitecoreContext()(BackgroundLayer);
