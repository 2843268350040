import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const Article = props => (
  <>
    <h2>
      <Text field={props.fields.heading} />
    </h2>
    <p>
      <Text field={props.fields.date} />
    </p>
    <RichText field={props.fields.content} />
  </>
);

export default Article;
