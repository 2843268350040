/* eslint-disable no-undef */
import React, { useEffect, useRef } from "react";
import { Direction } from "../Menus/NavigationData";
import { withRouter } from "react-router-dom";

const getDirectionFromHammer = direction => {
  switch (direction) {
    case Hammer.DIRECTION_DOWN:
      return Direction.UP;
    case Hammer.DIRECTION_UP:
      return Direction.DOWN;
  }
};

const SwipeHandler = props => {
  const navigationData = useRef();
  navigationData.current = props.navigationData;
  const route = useRef();
  route.current = props.route;
  const history = useRef();
  history.current = props.history;
  const state = React.useState(0);
  useEffect(() => {
    if (typeof window == "undefined") return;
    const Hammer = require("hammerjs");
    const mc = new Hammer(document, {
      touchAction: "auto",
      recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_VERTICAL }]]
    });
    let upEnabled = false;
    let downEnabled = false;
    document.addEventListener("touchstart", () => {
      var el = document.getElementsByTagName("html")[0];
      const body = document.getElementsByTagName("body")[0];
      const scrollTop = Math.max(el.scrollTop, body.scrollTop);
      const clientHeight = Math.max(el.clientHeight, body.clientHeight);
      const scrollHeight = Math.max(el.scrollHeight, body.scrollHeight);

      upEnabled = scrollTop <= 1;
      downEnabled = scrollTop + clientHeight > scrollHeight * 0.8;
      state[1](state[0]++);
    });
    mc.on("swipe", evt => {
      const root = document.querySelector("#root");
      if (root.classList.contains("displayBurgerMenu")) return;
      const direction = getDirectionFromHammer(evt.direction);
      if (direction == null) return;
      var el = document.getElementsByTagName("html")[0];
      const body = document.getElementsByTagName("body")[0];
      const scrollTop = Math.max(el.scrollTop, body.scrollTop);
      const clientHeight = Math.max(el.clientHeight, body.clientHeight);
      const scrollHeight = Math.max(el.scrollHeight, body.scrollHeight);

      if (direction == Direction.UP && (scrollTop > 1 || !upEnabled)) return;
      if (
        direction == Direction.DOWN &&
        (scrollTop + clientHeight <= scrollHeight * 0.99 || !downEnabled)
      )
        return;
      const data = navigationData.current.getNext(
        direction,
        navigationData.current.getSelectedPosition(route.current)
      );
      if (!data || !data.menuPosition) return;
      history.current.push(data.url);
    });
    return () => mc.destroy();
  }, []);
  return <></>;
};

export default withRouter(SwipeHandler);
