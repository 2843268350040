/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, {
  memo,
  useState,
  useRef,
  useContext,
  useEffect,
  //useLayoutEffect,
  useMemo
} from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import _ from "lodash";
import Menus from "./SubComponents/Menus";
import { NavigationData } from "./SubComponents/Menus/NavigationData";
import BackgroundLayer from "./SubComponents/BackgroundLayer";
import Navigation from "./SubComponents/Navigation";
import { loader as gqlLoader } from "graphql.macro";
import TransitionContext, {
  getTransitionFromRoute
} from "./SubComponents/TransitionContext";
import { useQuery } from "@apollo/react-hooks";
import { menuQueryResult } from "./lib/GraphQLMock";
import isClimate from "./isClimateWebsite";
import ContentWrapper from "./SubComponents/ContentWrapper";
import ScrollContext from "./SubComponents/ScrollContext";
import SwipeHandler from "./SubComponents/SwipeHandler";

const menuQuery = gqlLoader("./menuQuery.graphql");

import "normalize.css";
import "./assets/app.css";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
require("dotenv").config();
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = memo(({ route, loading }) => {
  const { loading: gqlLoading, error, data } =
    route.databaseName == "available-in-connected-mode"
      ? { data: menuQueryResult, error: null, loading: false }
      : useQuery(menuQuery);
  const navigation = useMemo(() => new NavigationData(data), [data]);
  const fields = route.fields;
  let overlayColor = "rgba(0, 0, 0, 0)";
  if (fields.overlay && fields.overlay.fields.backgroundColor.value) {
    overlayColor = fields.overlay.fields.backgroundColor.value;
  }
  const isDark = fields.isDark && fields.isDark.value;
  const transitionValue = getTransitionFromRoute(route);
  let title = _.get("route.fields.pageTitle.value") || "Nephila";
  if (title !== "Nephila") {
    title = title + " - Nephila";
  }
  const [prevRoute, setPrevRoute] = useState(route);
  const routeRef = useRef(route);
  routeRef.current = route;
  const transitionContext = useContext(TransitionContext);
  const [className, setClassName] = useState("main-page");

  useEffect(() => {
    if (loading) {
      setClassName("main-page disappear");
    }
    if (!loading && route == prevRoute) {
      //setClassName("MAI")
    }
  }, [loading, route, prevRoute]);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  useEffect(() => {
    if (route == prevRoute) return;
    setScrollEnabled(false);
    const tm = setTimeout(() => {
      setPrevRoute(route);
    }, transitionContext.initialPhaseTime);
    const tm2 = setTimeout(() => {
      setClassName("main-page");
      setScrollEnabled(true);
    }, transitionContext.initialPhaseTime + transitionContext.mainPhaseTime);
    return () => {
      clearTimeout(tm);
      clearTimeout(tm2);
    };
  }, [route]);
  const [appearClass, setAppearClass] = useState("");
  const [prevMainPage, setPrevMainPage] = useState(false);
  const isMainPage = route.name == "home";
  const isCookieStatementPage = route.name == "cookie-statement";
  //const isPrivacyPolicyPage = route.name == "privacy-policy";
  useEffect(() => {
    if (prevMainPage != isMainPage) {
      setAppearClass("disappear-main");
      setTimeout(() => setAppearClass("appear-main"), 0);
      setTimeout(
        () => setAppearClass(""),
        transitionContext.mainPhaseTime + transitionContext.initialPhaseTime
      );
    }
    setPrevMainPage(isMainPage);
  }, [isMainPage]);
  useEffect(() => {
    if (isCookieStatementPage) {
      const cookiePolicy = document.getElementById("ot-sdk-cookie-policy");
      if (cookiePolicy !== "undefined" || cookiePolicy !== null) {
        if (typeof OneTrust.initializeCookiePolicyHtml === "function") {
          OneTrust.initializeCookiePolicyHtml();
        }
      }
    }
  });
  const borderStyle = useMemo(
    () => ({
      borderTopColor: transitionValue.color
    }),
    [transitionValue.color]
  );
  const scrollRef = useRef();
  /*  useLayoutEffect(() => {
    if (isPrivacyPolicyPage) {
      //OneTrust.initializeCookiePolicyHtml(true);
      const legalSection = document.getElementsByClassName("legal-section")[0];
      if (legalSection !== "undefined" || legalSection !== null) {
        const otNoticeDiv = document.createElement("div");
        otNoticeDiv.id = "otnotice-edf46608-53c3-4f1b-8afe-5a9b31524ebb";
        otNoticeDiv.className = "otnotice";
        if (typeof legalSection.appendChild === "function") {
          legalSection.appendChild(otNoticeDiv);
        }

        const scriptTag = document.createElement("script");
        scriptTag.src =
          "https://privacyportal-uk-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
        scriptTag.type = "text/javascript";
        scriptTag.charset = "UTF-8";
        scriptTag.id = "otprivacy-notice-script";

        if (typeof legalSection.appendChild === "function") {
          legalSection.appendChild(scriptTag);
        }
        const otLoadScript = document.createElement("script");
        otLoadScript.type = "text/javascript";
        otLoadScript.charset = "UTF-8";
        otLoadScript.async = true;
        otLoadScript.innerHTML =
          "OneTrust.NoticeApi.Initialized.then(function() { OneTrust.NoticeApi.LoadNotices(['https://privacyportal-uk-cdn.onetrust.com/9dc67a41-b939-40e8-aa6c-1b49fbe70ae2/privacy-notices/draft/edf46608-53c3-4f1b-8afe-5a9b31524ebb.json'], false);});";

        document
          .getElementById("otprivacy-notice-script")
          .addEventListener("load", () => {
            legalSection.appendChild(otLoadScript);
          });

        return () => {
          // clean up the script when the component in unmounted
          //legalSection.removeChild(otNoticeDiv);
          //legalSection.removeChild(scriptTag);
          //legalSection.removeChild(otLoadScript);
        };
      }
    }
  }); */
  if (gqlLoading || error) return <></>;
  return (
    <>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            "Page"}
          {" - Nephila"}
        </title>
      </Helmet>
      <TransitionContext.Provider value={transitionValue}>
        <SwipeHandler navigationData={navigation} route={route} />
        <ScrollContext
          innerRef={scrollRef}
          enabled={scrollEnabled}
          route={prevRoute}
          navigationData={navigation}
        >
          <div className="top-border-div" style={borderStyle} />
          <BackgroundLayer route={route} />
          {isMainPage ? (
            <>
              <div className={className} ref={scrollRef}>
                <Placeholder name="jss-main" rendering={route} />
              </div>
            </>
          ) : (
            <>
              <div
                className={
                  appearClass +
                  (isDark ? " dark" : "") +
                  (isClimate ? " climate-website" : "")
                }
              >
                <Navigation isDark={isDark} isMain={isMainPage} />
                <Menus
                  navigationData={navigation}
                  route={route}
                  overlayColor={overlayColor}
                  data={data || {}}
                />
                <div
                  className={"route" + (isDark ? " dark" : "")}
                  ref={scrollRef}
                >
                  <div className="content-block">
                    <div className={className}>
                      <ContentWrapper
                        menuQueryResult={menuQueryResult}
                        route={prevRoute}
                        navigationData={navigation}
                      >
                        <Placeholder name="jss-main" rendering={prevRoute} />
                      </ContentWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </ScrollContext>
      </TransitionContext.Provider>
    </>
  );
});

export default Layout;
