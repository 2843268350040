/* eslint-disable no-undef */
import React, { useCallback, useEffect, memo } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { withRouter } from "react-router-dom";

const ContentBlock = props => {
  const { fields, history } = props;
  const onClick = useCallback(
    event => {
      event.preventDefault();
      history.push(event.target.pathname);
    },
    [history]
  );
  useEffect(() => {
    requestAnimationFrame(() => {
      const elements = document.querySelectorAll(
        ".contentDescription a[href^='/']"
      );
      for (const element of elements) {
        element.addEventListener("click", onClick);
      }
    });
  }, null /*every rerender*/);
  return <RichText className="contentDescription" field={fields.content} />;
};

export default withRouter(memo(ContentBlock));
