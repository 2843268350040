/* eslint-disable no-console */
/* eslint-disable no-undef */
import {
  Form,
  createDefaultFieldFactory,
  FieldTypes
} from "@sitecore-jss/sitecore-jss-react-forms";
import React, { useRef, useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { sitecoreApiHost, sitecoreApiKey } from "../../temp/config";
import ReCAPTCHA from "react-google-recaptcha";

const fieldFactory = createDefaultFieldFactory();
const SendMailContext = React.createContext([false, () => {}]);
const RecaptchaContext = React.createContext([false, () => {}]);

const rawHtmlFieldType = "{F6ABF2D4-D864-48D7-A464-75025A42162E}";
const RawHtmlRenderer = props => {
  return <p dangerouslySetInnerHTML={{ __html: props.field.model.html }} />;
};

fieldFactory.setComponent(rawHtmlFieldType, RawHtmlRenderer);

function handleOnChange(field, fieldValue, callback) {
  // (fieldName, fieldValue, isValid, validationErrors)
  let valid = true;
  const errorMessages = [];
  if (field.model.required && !fieldValue) {
    valid = false;
    errorMessages.push(`${field.model.title} is required`);
  }
  callback(field.valueField.name, fieldValue, valid, errorMessages);
}
fieldFactory.setComponent(FieldTypes.Button, props => (
  <div>{JSON.stringify(props)}</div>
));

const Button = props => {
  const { field, onButtonClick } = props;
  const [sendMail] = useContext(SendMailContext);
  const render = !!sendMail ^ (field.model.name == "SendWithoutContact");
  const [recaptchaValid] = useContext(RecaptchaContext);
  return render ? (
    <>
      <button
        type="submit"
        value={field.model.title}
        name={field.buttonField.name}
        id={field.buttonField.id}
        onClick={() => onButtonClick(field.buttonField.name)}
        disabled={!recaptchaValid}
      >
        {field.model.title}
      </button>
    </>
  ) : (
    <></>
  );
};
fieldFactory.setComponent(FieldTypes.Button, Button);

let recaptcha = props => {
  const key = props.field.model.captchaPublicKey;
  const origName = props.field.valueField.name;
  let [setRecaptcha] = useContext(RecaptchaContext);
  const recaptchaRef = useRef();
  const inputName =
    origName.substring(0, origName.length - "Value".length) + "CaptchaValue";
  useEffect(() => recaptchaRef.current && recaptchaRef.current.reset(), [
    props.field
  ]);
  return (
    <div className="contact-form-recaptcha">
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={key}
        size="normal"
        onChange={e => {
          props.onChange(inputName, e, true, []);
          setRecaptcha(!!e);
        }}
      />
    </div>
  );
};
fieldFactory.setComponent("{EB991D4F-A660-4AEC-B34A-3A7FA9C3B4A0}", recaptcha);
fieldFactory.setComponent("{E383BDE2-BC88-4278-83EF-832A15C9E94A}", recaptcha);
fieldFactory.setComponent(FieldTypes.Checkbox, props => {
  console.log(props);
  let [setSendEmail] = useContext(SendMailContext);
  if (props.field.model.name !== "emailCopy") setSendEmail = () => {};
  return (
    <div className="contact-form-full-width">
      <div className="pretty p-default p-round">
        <input
          type="checkbox"
          name={props.field.valueField.name}
          id={props.field.valueField.id}
          onChange={e => {
            setSendEmail(e.target.checked);
            handleOnChange(props.field, e.target.checked, props.onChange);
          }}
        />
        <div className="state p-default">
          <label htmlFor={props.field.valueField.id}>
            {props.field.model.title}
          </label>
        </div>
      </div>
      {!props.isValid && (
        <div className="invalid">
          {props.errors.map((e, i) => (
            <p key={i}>{e}</p>
          ))}
        </div>
      )}
    </div>
  );
});

const LabelComponent = () => <></>;
const ContactForm = ({ fields, history }) => {
  const sendMailState = useState(false);
  const recaptchaState = useState(false);

  return (
    <div className="contact-form">
      <SendMailContext.Provider value={sendMailState}>
        <RecaptchaContext.Provider value={recaptchaState}>
          <Form
            form={fields}
            labelComponent={LabelComponent}
            sitecoreApiHost={sitecoreApiHost}
            sitecoreApiKey={sitecoreApiKey}
            onRedirect={() => history.push("/contact")}
            fieldFactory={fieldFactory}
          />
        </RecaptchaContext.Provider>
      </SendMailContext.Provider>
    </div>
  );
};

export default withRouter(ContactForm);
