import React from "react";
import ConditionalStaticPlaceholder from "./ConditionalStaticPlaceholder";
import logoLight from "./logo-light.svg";
import logoDark from "./logo-dark.svg";
import { Link } from "react-router-dom";

function burgerMenuClick() {
  const root = document.querySelector("#root");
  const burgerMenuIcon = document.querySelector(".burgerMenuIcon");
  root.classList.toggle("displayBurgerMenu");
  burgerMenuIcon.classList.toggle("change");
}

const Navigation = ({ isDark }) => {
  return (
    <div className={"navigation"} role="navigation">
      <div className="logo">
        <Link to="/main">
          {isDark ? (
            <img src={logoDark} alt="Nephila" />
          ) : (
            <img src={logoLight} alt="Nephila" />
          )}
        </Link>
      </div>
      <div className="top-menu">
        <ConditionalStaticPlaceholder name="navigation" />
      </div>
      <div className="navigationBurgerMenu">
        <div className="burgerMenuIcon" onClick={burgerMenuClick}>
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
        </div>
      </div>
    </div>
  );
};
export default Navigation;
