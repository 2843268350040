/* eslint-disable no-empty */
import React, { useRef } from "react";
import { Link, withRouter } from "react-router-dom";

const isStart = d => {
  const match = d.url.match(/\//g);
  return match && match.length == 1;
};
const getColor = d =>
  (d.color && d.color.targetItem && d.color.targetItem.color.value) ||
  "#cccccc";
function burgerMenuLinkClick() {
  const root = document.querySelector("#root");
  const burgerMenuIcon = document.querySelector(".burgerMenuIcon");
  root.classList.toggle("displayBurgerMenu");
  burgerMenuIcon.classList.toggle("change");
}

function BurgerMenu({ data, location }) {
  const prevPath = useRef(location.pathname);
  if (prevPath.current != location.pathname) {
    prevPath.current = location.pathname;
    const root = document.querySelector("#root");
    if (root.classList.contains("displayBurgerMenu")) burgerMenuLinkClick();
    try {
      document.querySelector("html").scrollTo({ top: 0, behavior: "smooth" });
    } catch {} //Edge
  }
  return (
    <div className="burger-menu">
      <div className="burger-menu-content">
        <br />
        <React.Fragment key="login">
          <a href="https://portal.nephila.com/users/sign_in">Login</a>
        </React.Fragment>
        {data &&
          data.map(d => (
            <React.Fragment key={d.id}>
              {isStart(d) && <p>OK</p> && (
                <>
                  <hr style={{ borderColor: getColor(d) }} />
                  <Link
                    to={d.url}
                    className="burgerMenuHeader"
                    style={{ color: getColor(d), marginBlockStart: 0 }}
                  >
                    {d.pageTitle}
                  </Link>
                </>
              )}
              <Link to={d.url}>{d.menuTitle}</Link>
            </React.Fragment>
          ))}
        <hr style={{ borderColor: "rgb(248, 71, 94)" }} />
        <>
          <Link to="/disclaimer" onClick={burgerMenuLinkClick}>
            Disclaimer
          </Link>
          <Link to="/disclaimer/terms-of-use" onClick={burgerMenuLinkClick}>
            Terms of Use
          </Link>
          <Link to="/disclaimer/privacy-policy" onClick={burgerMenuLinkClick}>
            Privacy Policy
          </Link>
          <Link to="/disclaimer/cookie-statement" onClick={burgerMenuLinkClick}>
            Cookie Statement
          </Link>
          <Link
            to="/disclaimer/modern-slavery-act"
            onClick={burgerMenuLinkClick}
          >
            Modern Slavery Act
          </Link>
        </>
      </div>
    </div>
  );
}
export default withRouter(BurgerMenu);
