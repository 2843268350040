import React, { useContext, useMemo } from "react";
import TransitionContext from "../../SubComponents/TransitionContext";
import climateWebsite from "../../isClimateWebsite";
import NextPageLink from "../../SubComponents/NextPageLink";
import PreviousPageLink from "../../SubComponents/PreviousPageLink";
import { Direction } from "../Menus/NavigationData";

const ContentWrapper = ({ navigationData, route, children }) => {
  const pos = useMemo(() => navigationData.getSelectedPosition(route), [
    navigationData,
    route
  ]);
  const [i, j] = pos;
  const selectedItem =
    j == null ? navigationData.data[i] : navigationData.data[i].children[j];
  const previousPageData = navigationData.getNext(Direction.UP, pos);
  const nextPageData = navigationData.getNext(Direction.DOWN, pos);
  const breadCrumbs = navigationData.getBreadCrumbs(pos);
  const color = useContext(TransitionContext).color;
  return (
    <>
      <div className="spacer contentUpperSpacer">
        {breadCrumbs && (
          <div className="contentHeader" style={{ borderBottomColor: color }}>
            <h3>
              {breadCrumbs &&
                (breadCrumbs[1] != ""
                  ? breadCrumbs.join(" > ")
                  : breadCrumbs[0])}
            </h3>
          </div>
        )}
        {selectedItem &&
        selectedItem.multipartArticle &&
        climateWebsite &&
        previousPageData ? (
          <PreviousPageLink url={previousPageData && previousPageData.url} />
        ) : (
          ""
        )}
      </div>
      <div className="centered-content">{children}</div>
      <div className="spacer contentSpacer">
        {selectedItem &&
        selectedItem.multipartArticle &&
        climateWebsite &&
        nextPageData ? (
          <NextPageLink url={nextPageData && nextPageData.url} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ContentWrapper;
