import React, { useContext, useMemo } from "react";
import TransitionContext from "../TransitionContext";
import { useScrollContext } from "../ScrollContext";

const strokeWidth = 5;

/**
 * Render next page arrow using color from ColorContext and next page wheel
 * progress from ScrollContext
 */
const ArrowDown = props => {
  const { color } = useContext(TransitionContext);
  const fill = useScrollContext();
  const points = useMemo(() => {
    const delta = strokeWidth / Math.sqrt(2);
    const partFilled = Math.min(1, Math.max(0, fill));
    const x = -(60 + 2 * delta) * partFilled;
    const y = 80 + delta - (60 + 2 * delta) * partFilled;
    return `${x} ${y} 0 ${80 + delta} ${-x} ${y}`;
  }, [fill]);
  return (
    <div className="arrow-down-container" {...props}>
      <svg viewBox="-100 0 200 100" preserveAspectRatio="xMinYMin meet">
        <polyline
          points="-60 20 0 80 60 20"
          stroke={color}
          fill="none"
          strokeWidth={strokeWidth}
        />
        <polyline points={points} fill={color} />
      </svg>
    </div>
  );
};
export default ArrowDown;
