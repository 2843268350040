import React, { useMemo, useCallback, useContext, memo } from "react";
import Menu from "./Menu";
import TransitionContext from "../TransitionContext";
import BurgerMenu from "./BurgerMenu";
import ClimateBurgerMenu from "./ClimateBurgerMenu";
import climateWebsite from "../../isClimateWebsite";
import { Direction } from "./NavigationData";

const Menus = memo(({ route, overlayColor, navigationData }) => {
  const { color } = useContext(TransitionContext);

  const leftMenuData = useMemo(() => navigationData.getLeftMenuData(), [
    navigationData
  ]);
  const rightMenuData = useMemo(() => navigationData.getRightMenuData(route), [
    navigationData,
    route
  ]);
  const burgerMenuData = useMemo(
    () => (climateWebsite ? leftMenuData : navigationData.getBurgerMenuData()),
    [navigationData, leftMenuData]
  );
  const pos = useMemo(() => navigationData.getSelectedPosition(route), [
    navigationData,
    route
  ]);
  const [i, j] = pos;
  const selectedLeft = navigationData.data[i];
  const selectedRight =
    j == null ? navigationData.data[i] : navigationData.data[i].children[j];
  const previousPageData = navigationData.getNext(Direction.UP, pos);
  const nextPageData = navigationData.getNext(Direction.DOWN, pos);

  const leftMenuSelectedStyle = useCallback(
    () => ({
      backgroundColor: color,
      color: "#142a37"
    }),
    [color]
  );

  const rightMenuSelectedStyle = useCallback(
    () => ({ borderBottom: "solid " + color }),
    [color]
  );
  return (
    <>
      {climateWebsite ? (
        <ClimateBurgerMenu data={burgerMenuData} />
      ) : (
        <BurgerMenu data={burgerMenuData} />
      )}
      <Menu
        id="left-menu"
        data={leftMenuData}
        selected={selectedLeft}
        color={color}
        backgroundcolor={overlayColor}
        getSelectedStyle={leftMenuSelectedStyle}
      />
      <Menu
        data={rightMenuData}
        selected={selectedRight}
        color={color}
        divClass="right-menu"
        getSelectedStyle={rightMenuSelectedStyle}
        previousUrl={previousPageData && previousPageData.url}
        nextUrl={nextPageData && nextPageData.url}
        multipartArticle={selectedLeft && selectedLeft.multipartArticle}
      />
    </>
  );
});
export default Menus;
