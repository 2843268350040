// Graph ql mock for disconnected development
// Generated from menuQuery.graphql in Sitecore Experiance Graph Browser further transformed
// with data.contextItem.children.forEach(c => {c.id = c.name; c.children.forEach(cc => {cc.id = cc.name});});

export const menuQueryResult = {
  contextItem: {
    children: [
      {
        id: "51981B3FE06A5947A296B0B51A5DBC0F",
        name: "catastrophe",
        pageTitle: {
          value: "Portfolio Management"
        },
        menuTitle: {
          value: "Overview"
        },
        menuPosition: {
          value: "400"
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#3BCADA"
            }
          }
        },
        url: "/catastrophe",
        children: [
          {
            id: "AA7BE7319C035E69A287F1DDD7B4934F",
            name: "catastrophe-leadership",
            menuPosition: {
              value: "401"
            },
            pageTitle: {
              value: "Portfolio Management Leadership"
            },
            menuTitle: {
              value: "Leadership"
            },
            multipartArticle: {
              value: ""
            },
            url: "/catastrophe/catastrophe-leadership"
          }
        ]
      },
      {
        id: "8F56049CB5C15483977CC3EB2701E493",
        name: "climate",
        pageTitle: {
          value: "Climate"
        },
        menuTitle: {
          value: "Overview"
        },
        menuPosition: {
          value: "300"
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#26D07C"
            }
          }
        },
        url: "/climate",
        children: [
          {
            id: "F63996C9DA405CD199CC3DEE21590F1D",
            name: "climate-leadership",
            menuPosition: {
              value: "301"
            },
            pageTitle: {
              value: "Climate Leadership"
            },
            menuTitle: {
              value: "Leadership"
            },
            multipartArticle: {
              value: ""
            },
            url: "/climate/climate-leadership"
          }
        ]
      },
      {
        id: "87D989A4B7BF5F50AD004AB941AA6CF2",
        name: "contact",
        pageTitle: {
          value: "Contact"
        },
        menuTitle: {
          value: "Office Locations"
        },
        menuPosition: {
          value: "1200"
        },
        isHidden: {
          value: "1"
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#F8475E"
            }
          }
        },
        url: "/contact",
        children: [
          {
            id: "8706F39FE4A95841A0D50D4D3409E086",
            name: "careers",
            menuPosition: {
              value: "1202"
            },
            pageTitle: {
              value: "Careers"
            },
            menuTitle: {
              value: "Careers"
            },
            multipartArticle: {
              value: ""
            },
            url: "/contact/careers"
          },
          {
            id: "76B37E58747E5EF89CF38E63FDC2E934",
            name: "contact_form",
            menuPosition: {
              value: "1201"
            },
            pageTitle: {
              value: "Contact Form"
            },
            menuTitle: {
              value: "Contact Form"
            },
            multipartArticle: {
              value: ""
            },
            url: "/contact/contact_form"
          }
        ]
      },
      {
        id: "0AF0B4DBD82850E985560B722AE7CCD2",
        name: "disclaimer",
        pageTitle: {
          value: "Disclaimer"
        },
        menuTitle: {
          value: ""
        },
        menuPosition: {
          value: ""
        },
        isHidden: {
          value: "1"
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#F8475E"
            }
          }
        },
        url: "/disclaimer",
        children: [
          {
            id: "E64E5D0D6488583EA76649ED7E361B21",
            name: "privacy-policy",
            menuPosition: {
              value: ""
            },
            pageTitle: {
              value: "Privacy policy"
            },
            menuTitle: {
              value: ""
            },
            multipartArticle: {
              value: ""
            },
            url: "/disclaimer/privacy-policy"
          }
        ]
      },
      {
        id: "F98CCB3999FF560F8C7DE2BAE8755A4C",
        name: "main",
        pageTitle: {
          value: "Home"
        },
        menuTitle: {
          value: "Overview"
        },
        menuPosition: {
          value: "100"
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#F8475E"
            }
          }
        },
        url: "/main",
        children: [
          {
            id: "926C00A0AEBE5390888F8AAC84025E48",
            name: "main-about",
            menuPosition: {
              value: "101"
            },
            pageTitle: {
              value: "About"
            },
            menuTitle: {
              value: "About"
            },
            multipartArticle: {
              value: ""
            },
            url: "/main/main-about"
          },
          {
            id: "main-founders",
            name: "main-founders",
            menuPosition: {
              value: "102"
            },
            pageTitle: {
              value: "Founders"
            },
            menuTitle: {
              value: "Founders"
            },
            multipartArticle: {
              value: ""
            },
            url: "/main/main-founders"
          },
          {
            id: "EBFD4DE43AE55E5BA2C285D94073BC29",
            name: "main-leadership",
            menuPosition: {
              value: "103"
            },
            pageTitle: {
              value: "Leadership"
            },
            menuTitle: {
              value: "Leadership"
            },
            multipartArticle: {
              value: ""
            },
            url: "/main/main-leadership"
          }
        ]
      },
      {
        id: "E2346D51C3595B7FA0778DF37B2436D9",
        name: "navigation",
        pageTitle: {
          value: ""
        },
        menuTitle: {
          value: ""
        },
        menuPosition: {
          value: ""
        },
        isHidden: {
          value: "1"
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: null
        },
        url: "/navigation",
        children: []
      },
      {
        id: "AA8F03C10F2A5E489054AD3ED5186109",
        name: "reinsurance",
        pageTitle: {
          value: "Reinsurance"
        },
        menuTitle: {
          value: "Overview"
        },
        menuPosition: {
          value: "500"
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#AC4FC5"
            }
          }
        },
        url: "/reinsurance",
        children: [
          {
            id: "924719B90DA956AC90B2E03D35A4042D",
            name: "reinsurance-leadership",
            menuPosition: {
              value: "501"
            },
            pageTitle: {
              value: "Reinsurance Leadership"
            },
            menuTitle: {
              value: "Leadership"
            },
            multipartArticle: {
              value: ""
            },
            url: "/reinsurance/reinsurance-leadership"
          }
        ]
      },
      {
        id: "14099F6459C15A19BA3D579929A50F56",
        name: "syndicate",
        pageTitle: {
          value: "Lloyd’s Syndicate"
        },
        menuTitle: {
          value: "Overview"
        },
        menuPosition: {
          value: "600"
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#307EE1"
            }
          }
        },
        url: "/syndicate",
        children: [
          {
            id: "EA2427A2192955A5B758A90C1834F9C5",
            name: "syndicate-leadership",
            menuPosition: {
              value: "601"
            },
            pageTitle: {
              value: "Syndicate Leadership"
            },
            menuTitle: {
              value: "Leadership"
            },
            multipartArticle: {
              value: ""
            },
            url: "/syndicate/syndicate-leadership"
          }
        ]
      }
    ]
  }
};
