import React, { useCallback } from "react";

import MainPageArrow from "./MainPageArrow.svg";
import ClimatePageArrow from "./ClimatePageArrow.svg";
import isClimate from "../../isClimateWebsite";
import { withRouter } from "react-router-dom";

const IndexArrowLink = ({ history }) => {
  const handleClick = useCallback(() => {
    history.push("/main");
  }, []);
  return (
    <>
      <div className="index-image layout" onClick={handleClick} />
      <div className="index-arrow">
        <img src={isClimate ? ClimatePageArrow : MainPageArrow} />
      </div>
    </>
  );
};

export default withRouter(IndexArrowLink);
