import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Direction } from "../Menus/NavigationData";

const ScrollInnerContext = React.createContext(0);

/**
 * How much should user scroll to go to next page
 */
const scrollToNextPage = 20;

/**
 * Adds context that tracks mouse wheel after page end and before page start
 * for navigation needs.
 *
 * Value returned by useScrollContext is in range from -1 to 1 where 1 means
 * that user want to go to next page (scrolls down) and -1 that he wants to go back.
 *
 * Intermediate values depend on scrollToNextPage constant.
 */
const ScrollContext = ({
  innerRef,
  children,
  enabled,
  navigationData,
  route
}) => {
  let [count, setCount] = useState(0);
  const countRef = useRef(0);
  const updateRef = useRef();
  const pos = useMemo(() => navigationData.getSelectedPosition(route), [
    navigationData,
    route
  ]);
  const previousPageData = navigationData.getNext(Direction.UP, pos);
  const nextPageData = navigationData.getNext(Direction.DOWN, pos);
  useEffect(() => {
    setCount(0);
    countRef.current = 0;
  }, [enabled, route, nextPageData, previousPageData]);

  const handleWheel = e => {
    if (!enabled) return;
    if (updateRef.current) {
      window.cancelAnimationFrame(updateRef.current);
      updateRef.current = undefined;
    }
    if (!innerRef.current) return;

    const scrollTop = innerRef.current.scrollTop;
    const clientHeight = innerRef.current.clientHeight;
    const scrollHeight = innerRef.current.scrollHeight;
    if (
      (scrollTop + clientHeight >= 0.99 * scrollHeight && e.deltaY > 0) ||
      (scrollTop < 1 && e.deltaY < 0)
    ) {
      countRef.current =
        countRef.current +
        Math.max(Math.min(e.deltaY, 1), -1) / scrollToNextPage;
    } else countRef.current = 0;

    if (count !== countRef.current) {
      updateRef.current = window.requestAnimationFrame(() => {
        updateRef.current = undefined;
        if (count !== countRef.current) {
          setCount(countRef.current);
        }
      });
    }
  };

  return (
    <div onWheel={handleWheel}>
      <ScrollInnerContext.Provider value={count}>
        {count >= 1 && enabled && nextPageData && (
          <Redirect to={nextPageData.url} />
        )}
        {count <= -1 && enabled && previousPageData && (
          <Redirect to={previousPageData.url} />
        )}
        {children}
      </ScrollInnerContext.Provider>
    </div>
  );
};

/**
 * Hook to use ScrollContext value, 1 go to next page, -1 go to previous
 * 0 internal scrolling
 */
export function useScrollContext() {
  return useContext(ScrollInnerContext);
}

export function withScrollScontext(Component) {
  const Scrolled = props => {
    const scroll = useScrollContext();

    return <Component {...props} scroll={scroll} />;
  };
  Scrolled.displayName = `withScrollContext(${Component.displayName})`;
  return Scrolled;
}

export default ScrollContext;
