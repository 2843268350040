import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import { withRouter } from "react-router-dom";

const PublicationRecord = props => {
  const { fields } = props;
  return (
    <div className="publicationRecord">
      <div className="publicationHeader">
        <Text field={fields.title} />
      </div>
      <div className="publicationDescription">
        <Link field={fields.url} />
      </div>
    </div>
  );
};

export default withRouter(PublicationRecord);
